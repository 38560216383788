import Topbar from '@/components/topbar/topbar_component.vue';
import Navbar from '@/components/navbar/navbar_component.vue';
import Popover from '@/components/popovers/popover_component.vue';
import moment from 'moment';

export default{
    name : 'groups',
    components:{
        Topbar,
        Navbar,
        Popover,
    },
    data(){
        return{
            open_popup_type:'',
            group_popup : false,
            selected_grp_name : '',
            selected_grp_id : '',
            selected_grp_index : -1,
            delete_grp_id : '',
            delete_grp_index : -1,
        }
    },

    async mounted(){
        await this.$store.dispatch('getGroups',[this.$store.state.api,{"org_id" : this.$store.state.org_id}])
    },

    methods:{

        toast(variant, title, message, append = false) {
            this.counter++
            this.$bvToast.toast(message, {
              title: title,
              solid: true,
              variant : variant,
              appendToast: append
            })
        },
        open_type_of_popup(type,grp_id,grp_name,index){
            switch(type){
                case 'add':
                    this.group_popup = true;
                    this.open_popup_type = 'view_group';
                    this.selected_grp_name = grp_name;
                    this.selected_grp_id = grp_id;
                    this.selected_grp_index = index;
                    break;
                case 'view':
                    this.group_popup = true;
                    this.open_popup_type = 'view_group';
                    this.selected_grp_name = grp_name;
                    this.selected_grp_id = grp_id;
                    this.selected_grp_index = index;
                    break;
                case 'delete':
                    this.group_popup = true;
                    this.open_popup_type = 'delete_popup_group';
                    this.delete_grp_id = grp_id;
                    this.delete_grp_index = index;
                    break;

            }
        },

        async handle_group(val){
            let group_obj = {};

            if(val[0]=='new'){
                group_obj = {
                    "org_id": this.$store.state.org_id,     
                    "group_name": val[1],     
                }
            }else if(val[0]=='edit'){
                group_obj = {
                    "id": this.selected_grp_id,
                    "group_name": val[1],
                }
            }

            if(val[0] == 'new'){
                await this.$store.dispatch('SaveGroup',[this.$store.state.api, group_obj]);
                this.$store.state.groups_obj.push(this.$store.state.save_group_data);
                this.toast('info','Success','Added group successfully'); 
            }else if(val[0]=='edit'){
                await this.$store.dispatch('SaveGroup',[this.$store.state.api, group_obj]);
                this.$store.state.groups_obj[this.selected_grp_index].group_name = val[1];
                this.toast('info','Success','Updated group successfully'); 
            }else{
                this.$store.commit("deleteGroup",{"group_id":this.delete_grp_id});
                this.$store.state.groups_obj.splice(this.delete_grp_index,1);
                this.toast('info','Success','Deleted group successfully');
            }
            this.group_popup = false;
        }
    },

    filters:{
        regular_format(date){
            return moment(date ,'YYYY-MM-DD HH:mm:ss').format("DD MMM, YYYY");
        },
    }
}